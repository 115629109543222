import React, { Fragment } from 'react';
import TopSearch from '../components/top-search/TopSearch';
import GridView from '../components/grid-view/GridView';

const SearchPage = () => {
    return (
        <Fragment>
            <div className='container page-fill'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='top-img-card'>
                        <div style={{height: '120px', backgroundColor: '#EBEBEB', borderRadius: '0px 0px 18px 18px'}}> </div>
                            <div className='top-img-text'>
                                <TopSearch />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 '>
                        <GridView limit={24} skeletonLimit={8} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SearchPage;
