import React, { Fragment } from 'react';
import './GridCardSkeletonLoading.scss';

const GridCardSekeletonLoading = ({ skeletonLimit = 1 }) => {
    return (
        <Fragment>
            {Array.from({ length: skeletonLimit }, (_, i) => (
                <div key={`skeleton-${i}`} className='grid-card-skeleton-loading-component col-12 col-sm-6 col-md-4 col-lg-3 mt-3 mb-2'>
                    <div className='card'>
                        <div className='card-img-top skeleton-color' />
                        <div className='card-fav' />
                        <div className='card-body'>
                            <div className='card-rating skeleton-color' />
                            <div className='card-title skeleton-color' />
                            <div className="card-subtitle  skeleton-color" />
                            <div className='card-text  skeleton-color' />
                            <div className='card-price  skeleton-color' />
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>

    );
};

export default GridCardSekeletonLoading;

