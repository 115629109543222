import React, { Fragment, useEffect, useState } from 'react';
import './Popup.scss';

const Popup = ({ children, modalHeading, modalId, setModalId }) => {

    const [show, setShow] = useState('');

    useEffect(() => {
        if (modalId !== null) {
            document.body.classList.add('modal-open');
            setShow('show');
        } else {
            document.body.classList.remove('modal-open');
            setShow('');
        }
        return () => {
            document.body.classList.remove('modal-open');
            setShow('');
        }
    }, [modalId]);

    return (
        <Fragment>
            <div key={modalId} className='popup-component'>
                <div className={`modal ${show}`} tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable show modal-lg modal-fullscreen-md-down" style={{ display: 'block' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalHeading}
                                </h5>
                                <i className='material-icons modal-close-btn' aria-label="Close" onClick={() => setModalId(null)}>clear</i>
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Popup;
