import React, { useState, useContext, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../user-context/UserContext';
import { Link } from 'react-router-dom';
import './Navigation.scss';
import logo from '../../logo.svg'
import { signOut } from 'firebase/auth';
import { auth } from '../../config/FirebaseConfig';

import Popup from '../popup/Popup';

import AuthService from '../../services/AuthService';

const Navigation = () => {
    const { user, setUser } = useContext(UserContext);

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(null);

    const [show, setShow] = useState('collapse navbar-collapse');
    const [modalId, setModalId] = useState(null);

    const onBurgerMenuClick = (menu = true) => {
        if (window?.innerWidth < 768) {
            if (show === 'collapse navbar-collapse' && menu) {
                setShow('navbar-collapse collapsing');
                setTimeout(() => setShow('collapse navbar-collapse show'), 100)
            } else {
                setShow('navbar-collapse collapsing');
                setTimeout(() => setShow('collapse navbar-collapse'), 100)
            }
        }
    }

    const handleLogout = () => {
        signOut(auth).then(() => {
            setUser({})
            navigate("/");
        });
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        const service = new AuthService();
        const response = await service.signIn(email, password);
        if (response) {
            setUser(response);
            setModalId(null);
        }
        setModalId(null);
    }

    const handleSignup = async (e) => {
        e.preventDefault();
        if (!emailValid || !passwordValid || !confirmPasswordValid || password !== confirmPassword) {
            return;
        }

        const service = new AuthService();
        const response = await service.createUser(email, password);
        if (response) {
            setUser({})
            setModalId('popupModalLogin');
        }
        setModalId('popupModalLogin');
    }

    const modalLogin = () => {
        setEmail('');
        setEmailValid(null);
        setPassword('');
        setPasswordValid(null);
        setConfirmPassword('');
        setConfirmPasswordValid(null);
        setModalId('popupModalLogin');
    }

    const modalSignup = () => {
        setEmail('');
        setEmailValid(null);
        setPassword('');
        setPasswordValid(null);
        setConfirmPassword('');
        setConfirmPasswordValid(null);
        setModalId('popupModalSignup');
    }

    return (
        <div className='navigation-component'>
            {/*To Navigation*/}
            <nav className="navbar fixed-top navbar-expand-md navbar-light bg-white border-bottom">
                <div className="container">
                    <Link className="navbar-brand" to="/" onClick={() => onBurgerMenuClick(false)}>
                        <img src={logo} alt="" height="38" className="d-inline-block align-text-top" />
                    </Link>
                    <button className="btn btn-outline-dark d-block d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={() => onBurgerMenuClick()}>
                        <i className="material-icons font-large-mui menu-icon">menu</i>
                    </button>
                    <div className={`${show} justify-content-center`} id="navbarNav">
                        <ul className="navbar-nav ml-auto mr-auto">
                            <li className="nav-item rounded-pill mt-4 mt-md-0">
                                <Link className="nav-link" to="/soekbyons" onClick={() => onBurgerMenuClick()}>Soek by ons</Link>
                            </li>
                            <li className="nav-item rounded-pill">
                                <Link className="nav-link" to="/lysbyons" onClick={() => onBurgerMenuClick()}>Lys by ons</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="dropdown d-none d-md-block">
                        <button className="btn btn-outline-dark rounded-pill px-2 py-1 menu-icon profile-icons" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="material-icons font-large-mui">menu</i><i className="material-icons font-large-mui">account_circle</i>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end mt-2 border-0" aria-labelledby="dropdownMenuButton">
                            {!user?.uid && <li>
                                {<Link className="dropdown-item py-2" onClick={() => modalSignup()}>Teken aan</Link>}

                            </li>}
                            {!user?.uid && <li><Link className="dropdown-item py-2" onClick={() => modalLogin()}>Teken in</Link></li>}
                            {user?.uid && <li><Link className="dropdown-item py-2" onClick={() => handleLogout()}>Teken uit</Link></li>}
                            <li><hr className="dropdown-divider" /></li>
                            <li><Link className="dropdown-item py-2" to="/kansellasiebeleid">Kansellasie beleid</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/*Bottom App Bar*/}
            <nav className="navbar fixed-bottom navbar-light bg-white d-block d-md-none border-top">
                <div className="container justify-content-center">
                    <Link className="btn btn-white py-0 button-hover-disable" to="/soekbyons">
                        <i className="material-icons font-large-mui appbar-icon">search</i>
                        <p className='m-0 font-small'>Soek</p>
                    </Link>
                    <div className='mx-sm-5 mx-2'></div>
                    <Link className="btn btn-white py-0 button-hover-disable" to="/wenslys">
                        <i className="material-icons font-large-mui appbar-icon">favorite</i>
                        <p className='m-0 font-small'>Wenslys</p>
                    </Link>
                    <div className='mx-sm-5 mx-2'></div>
                    <Link className="btn btn-white py-0 button-hover-disable" onClick={() => { !user?.uid ? modalLogin() : handleLogout() }}>
                        <i className="material-icons font-large-mui appbar-icon">account_circle</i>
                        {!user?.uid && <p className='m-0 font-small'>Teken in</p>}
                        {user?.uid && <p className='m-0 font-small'>Teken uit</p>}
                    </Link>
                </div>
            </nav>
            {/* Login Modal */}
            {modalId === 'popupModalLogin' && <Popup modalHeading={'Teken in'} modalId={modalId} setModalId={(e) => setModalId(e)} >
                <Fragment>
                    <form className="needs-validation" noValidate onSubmit={(e) => handleLogin(e)}>
                        <div className="modal-body form-modal row g-3">
                            <div className='input-group col-12'>
                                <div className="form-floating">
                                    <input type="email" className={`form-control ${emailValid === null ? '' : emailValid ? 'is-valid' : 'is-invalid'}`} id="floatingEmail" placeholder="E-pos adres" value={email} onChange={(e) => { setEmailValid(/^\S+@\S+\.\S+$/.test(e.target.value.trim())); setEmail(e.target.value) }} />
                                    <label htmlFor="floatingEmail">E-pos adres</label>
                                    <div className="invalid-feedback">
                                        {`Please provide ${email === '' ? 'your' : 'a valid'} email address.`}.
                                    </div>
                                </div>
                            </div>
                            <div className='input-group col-12'>
                                <div className="form-floating">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Wagwoord" value={password} onChange={(e) => { setPasswordValid(e.target.value.trim() !== ''); setPassword(e.target.value) }} />
                                    <label htmlFor="floatingPassword">Wagwoord</label>
                                    <div className="invalid-feedback">
                                        Invalid
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary rounded-pill" onClick={() => setModalId(null)}>MAAK TOE</button>
                            <button className='btn btn-link rounded-pill' onClick={() => setModalId('popupModalSignup')} >Teken aan</button>
                            <button type="button" className="btn btn-primary rounded-pill" onClick={(e) => handleLogin(e)}>TEKEN IN</button>
                        </div>
                    </form>
                </Fragment>
            </Popup>}
            {/* Signup Modal */}
            {modalId === 'popupModalSignup' && <Popup modalHeading={'Teken aan'} modalId={modalId} setModalId={(e) => setModalId(e)} >
                <Fragment>
                    <form className="needs-validation" noValidate onSubmit={(e) => handleSignup(e)}>
                        <div className="modal-body form-modal row g-3">
                            <div className='input-group col-12'>
                                <div className="form-floating">
                                    <input type="email" className={`form-control ${emailValid === null ? '' : emailValid ? 'is-valid' : 'is-invalid'}`} id="floatingEmail" placeholder="E-pos adres" value={email} onChange={(e) => { setEmailValid(/^\S+@\S+\.\S+$/.test(e.target.value.trim())); setEmail(e.target.value) }} />
                                    <label htmlFor="floatingEmail">E-pos adres</label>
                                    <div className="invalid-feedback">
                                        {`Please provide ${email === '' ? 'your' : 'a valid'} email address.`}.
                                    </div>
                                </div>
                            </div>
                            <div className='input-group col-12'>
                                <div className="form-floating">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Wagwoord" value={password} onChange={(e) => { setPasswordValid(e.target.value.trim() !== ''); setPassword(e.target.value) }} />
                                    <label htmlFor="floatingPassword">Wagwoord</label>
                                    <div className="invalid-feedback">
                                        Invalid
                                    </div>
                                </div>
                            </div>
                            <div className='input-group col-12'>
                                <div className="form-floating">
                                    <input type="password" className="form-control" id="floatingConfirmPassword" placeholder="Bevestig wagwoord" value={confirmPassword} onChange={(e) => { setConfirmPasswordValid(e.target.value.trim() !== ''); setConfirmPassword(e.target.value) }} />
                                    <label htmlFor="floatingConfirmPassword">Bevestig wagwoord</label>
                                    <div className="invalid-feedback">
                                        Invalid
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary rounded-pill" onClick={() => setModalId(null)}>MAAK TOE</button>
                            <button className='btn btn-link rounded-pill' onClick={() => setModalId('popupModalLogin')} >Teken in</button>
                            <button type="submit" className="btn btn-primary rounded-pill">TEKEN AAN</button>
                        </div>
                    </form>
                </Fragment>
            </Popup>}
        </div>
    );
};

export default Navigation;
