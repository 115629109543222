/*const FirebaseConfig = {
  apiKey: "AIzaSyCig9bsdy8KheLuWhiEuprXz5g9BUJgOIo",
  authDomain: "jagtog-01.firebaseapp.com",
  projectId: "jagtog-01",
  storageBucket: "jagtog-01.appspot.com",
  messagingSenderId: "401088210837",
  appId: "1:401088210837:web:70f6bd86c355b673b4789c"
};

export default FirebaseConfig;*/

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyCig9bsdy8KheLuWhiEuprXz5g9BUJgOIo",
    authDomain: "jagtog-01.firebaseapp.com",
    projectId: "jagtog-01",
    storageBucket: "jagtog-01.appspot.com",
    messagingSenderId: "401088210837",
    appId: "1:401088210837:web:70f6bd86c355b673b4789c"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;