import React, { Fragment } from 'react';
import './ItemViewSkeletonLoading.scss';

const ItemViewSkeletonLoading = () => {
    return (
        <Fragment>
            <div className="item-view-skeleton-loading-component">
                <div className='row d-md-block d-none'>
                    <div className='col-12 my-2 info-summary'>
                        <div className='item-title skeleton-color' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-6 pe-md-1'>
                        <div className='main-image skeleton-color' />
                    </div>
                    <div className='col-6 ps-1 d-md-block d-none'>
                        <div className='row'>
                            <div className='col-6 pe-1 pb-1'>
                                <div className='extra-image-01 skeleton-color' />
                            </div>
                            <div className='col-6 ps-1 pb-1'>
                                <div className='extra-image-02 skeleton-color' />
                            </div>
                            <div className='col-6 pe-1 pt-1'>
                                <div className='extra-image-03 skeleton-color' />
                            </div>
                            <div className='col-6 ps-1 pt-1'>
                                <div className='extra-image-04 skeleton-color' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-7 my-2 info-summary' style={{ minHeight: '800px' }}>
                        <div className='item-title d-block d-md-none skeleton-color'/>
                        <div className='item-description skeleton-color'/>
                        <div className='item-subtitle skeleton-color'/>
                        <div className='item-rating skeleton-color'/>
                        <div className='item-divider'/>
                    </div>
                    <div className='col-12 col-md-5 d-none d-md-block my-2 sticky-card-contianer'>
                        <div className='sticky-card skeleton-color' style={{ minHeight: '400px' }}/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ItemViewSkeletonLoading;