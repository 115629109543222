import { collection, getDocs, getDoc, doc, /*query, where*/ } from 'firebase/firestore';
import { db } from '../config/FirebaseConfig';
//import { getAuth } from 'firebase/auth';

class ListingService {
    getListings = async () => {
        try {
            //const snapshot = await getDocs(query(collection(db, 'listing'), where('uid', '==', getAuth().currentUser.uid)));
            const snapshot = await getDocs(collection(db, 'listing'));
            const listingList = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id}));
            return listingList;
        } catch (error) {
            return [];
        }
    }

    getListingById = async (id) => {
        try {
            const snapshot = await getDoc(doc(db, "listing", id));
            return { ...snapshot.data(), id: snapshot.id };
        } catch (error) {
            return {};
        }
    }
}

export default ListingService;