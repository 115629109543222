import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import topBackground from '../images/TopBackground.webp';
import TopSearch from '../components/top-search/TopSearch';
import GridView from '../components/grid-view/GridView';
import ContactUs from '../components/contact-us/ContactUs';

import KuduIcon01 from '../icons/KuduIcon01.svg';
import KuduIcon02 from '../icons/KuduIcon07.svg';
import KuduIcon03 from '../icons/KuduIcon06.svg';
import KuduIcon04 from '../icons/KuduIcon10.svg';

const HomePage = () => {
    return (
        <Fragment>
            <div className='container page-fill'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='top-img-card'>
                            <img className='img-fluid' src={topBackground} alt='top background' />
                            <div className='top-img-text'>
                                <TopSearch />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='text-center pt-5'>
                            <h5>Soek by ons</h5>
                        </div>
                        <div className='text-center pt-3 pb-3'>
                            <p className='text-justify'>
                                Ervaar die opwinding van die jagveld met ons onvergeetlike Jagtog-verblywe! Ons bied 'n unieke kans vir avontuurlustige jagliefhebbers om die natuur te verken terwyl hulle die perfekte skoot najaag. Vanaf gemaklike verblyf tot 'n wêreld van wildsbestuur, bied ons die ideale jagtog-ervaring vir diegene wat die wildernis wil ervaar.
                                <br />
                                <br />
                                Hierdie is nie net 'n verblyf nie; dit is 'n uitnodiging tot 'n onvergeetlike jagavontuur waar elke dageraad 'n nuwe moontlikheid bied vir opwinding en ontdekking. Gereed vir jou volgende jagtog-avontuur? Kom sluit aan by ons vir 'n ervaring wat jou jag- en natuurliefde tot nuwe hoogtes sal opstoot!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 '>
                        <GridView limit={5} skeletonLimit={4} sliding={true} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 text-center pt-5'>
                        <Link className='btn btn-primary btn-block' to="/soekbyons">Sien meer</Link>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='text-center pt-5'>
                            <h5>Hoekom Jagtog</h5>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div className='text-center pt-3 pb-3'>
                            <img src={KuduIcon01} alt='kudu icon' height={'56px'} width={'56px'} />
                            <p>
                                Jagveld opwinding ervaar.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div className='text-center pt-3 pb-3'>
                            <img src={KuduIcon02} alt='kudu icon' height={'56px'} width={'56px'} />
                            <p>
                                Unieke jaggeleentheid verken.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div className='text-center pt-3 pb-3'>
                            <img src={KuduIcon03} alt='kudu icon' height={'56px'} width={'56px'} />
                            <p>
                                Ideale jagtog-ervaring bied.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div className='text-center pt-3 pb-3'>
                            <img src={KuduIcon04} alt='kudu icon' height={'56px'} width={'56px'} />
                            <p>
                                Onvergeetlike jagavontuur uitnodiging.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='text-center pt-5'>
                            <h5>Lys by ons</h5>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='text-center pt-3 pb-3'>
                            <p className='text-justify'>
                                Wil jy jou stukkie jagparadys deel met avontuurlustige jagliefhebbers van reg oor Suid-Afrika? Sluit aan by ons jagtog-verblyfnetwerk en laat jou eiendom 'n bestemming word vir diegened wat die opwinding van die jagveld soek!
                                <br />
                                <br />
                                As gasheer bied jy nie net 'n verblyf nie, maar 'n ware jagervaring in 'n idilliese omgewing. Jou eiendom kan 'n oase van rus en avontuur wees vir diegene wat die natuur waardeer en op soek is na 'n onvergeetlike jagtog. Van weelderige jagplase tot rustieke wildsverblywe, ons platform gee jou die geleentheid om jou stukkie paradys met die wêreld te deel.
                                <br />
                                <br />
                                Sluit vandag aan en word deel van ons passievolle gemeenskap van jagliefhebbers en natuurontdekkers. Jou unieke aanbod kan die drome van avontuurlustiges laat waar word en jou as 'n topbestemming vir jagtog-ervarings vestig. Maak gereed om jou deure oop te stel vir 'n wêreld van jagliefhebbers wat jou jagland met groot opgewondenheid sal ontdek!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <ContactUs />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default HomePage;
