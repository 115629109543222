import React, { useState, useEffect, useRef, Fragment } from 'react';
import './GooglePlacesAutocompleteSearch.scss';

const Autocomplete = ({ className, type, placeholder, setOutput, input, setInput, setNext }) => {
  const [results, setResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const inputRef = useRef(null);

  const [blockSearch, setBlockSearch] = useState(false);

  useEffect(() => {
    if (!blockSearch) {
      if (input.trim() === '') {
        setBlockSearch(false);
        setResults([]);
        setSelectedItem(null);
      } else {
        const debouncedSearch = setTimeout(async () => {
          const searchResults = await searchFunction(input);
          setResults(searchResults);
          setSelectedItem(null);
        }, 300);

        return () => clearTimeout(debouncedSearch);
      }
    }
    setBlockSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  useEffect(() => {

    if (results.length > 0) {
      setOutput(<ul className='google-places-search-results'>
        {results.map((place, index) => (
          <li
            key={place.id}
            className={index === selectedItem ? 'selected rounded-pill' : 'rounded-pill'}
            onClick={() => handleResultSelection(place.description)}
          >
            <i className="material-icons">
              location_on
            </i>
            {place.description}
          </li>
        ))}
      </ul>)
    } else {
      setOutput(null)
    }


    const handleKeyDown = (event) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
        setBlockSearch(true);
        const direction = event.key === 'ArrowDown' ? 1 : -1;
        let newIndex =
          (selectedItem === null ? -1 : selectedItem) + direction;

        if (newIndex >= results.length) {
          newIndex = 0;
        } else if (newIndex < 0) {
          newIndex = results.length - 1;
        }

        setSelectedItem(
          Math.max(0, Math.min(newIndex, results.length - 1))
        );
        setInput(results[newIndex]?.description);
      } else if (event.key === 'Enter' && selectedItem !== null) {
        event.preventDefault();
        setBlockSearch(true);
        handleResultSelection(results[selectedItem].description);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, results]);

  const searchFunction = async (searchQuery) => {
    /*const response = await fetch(
      `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${searchQuery}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`
    );*/
    //const data = await response.json();
    //return data.predictions;
    const data = {
      predictions: [
        {
          description: 'Eastern Cape, South Africa',
          id: '1',
        },
        {
          description: 'Free State, South Africa',
          id: '2',
        },
        {
          description: 'Gauteng, South Africa',
          id: '3',
        },
        {
          description: 'KwaZulu-Natal, South Africa',
          id: '4',
        },
        {
          description: 'Limpopo, South Africa',
          id: '5',
        },
        {
          description: 'Mpumalanga, South Africa',
          id: '6',
        },
        {
          description: 'Northern Cape, South Africa',
          id: '7',
        },
        {
          description: 'North West, South Africa',
          id: '8',
        },
        {
          description: 'Western Cape, South Africa',
          id: '9',
        },
        {
          description: 'Port Elizabeth, South Africa',
          id: '10',
        },
        {
          description: 'Bloemfontein, South Africa',
          id: '11',
        },
        {
          description: 'Johannesburg, South Africa',
          id: '12',
        },
        {
          description: 'Durban, South Africa',
          id: '13',
        },
        {
          description: 'Polokwane, South Africa',
          id: '14',
        },
        {
          description: 'Nelspruit, South Africa',
          id: '15',
        },
        {
          description: 'Kimberley, South Africa',
          id: '16',
        },
        {
          description: 'Rustenburg, South Africa',
          id: '17',
        },
        {
          description: 'Mafikeng, South Africa',
          id: '18',
        },
        {
          description: 'Cape Town, South Africa',
          id: '19',
        },
        {
          description: 'Pretoria, South Africa',
          id: '20',
        },
        {
          description: 'Port Shepstone, South Africa',
          id: '21',
        },
        {
          description: 'George, South Africa',
          id: '22',
        },
        {
          description: 'Vryheid, South Africa',
          id: '23',
        },
        {
          description: 'Upington, South Africa',
          id: '24',
        },
        {
          description: 'Thohoyandou, South Africa',
          id: '25',
        },
        {
          description: 'Witbank, South Africa',
          id: '26',
        },
        {
          description: 'Middelburg, South Africa',
          id: '27',
        },
        {
          description: 'Bethlehem, South Africa',
          id: '28',
        },
        {
          description: 'Secunda, South Africa',
          id: '29',
        },
        {
          description: 'Port Alfred, South Africa',
          id: '30',
        },
        {
          description: 'Richards Bay, South Africa',
          id: '31',
        },
        {
          description: 'Grahamstown, South Africa',
          id: '32',
        },
        {
          description: 'Klerksdorp, South Africa',
          id: '33',
        },
        {
          description: 'Worcester, South Africa',
          id: '34',
        },
        {
          description: 'Paarl, South Africa',
          id: '35',
        },
        // Add more cities as needed
      ]
    }

    return data.predictions.filter(
      (prediction) => prediction.description.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleResultSelection = (selectedResult) => {
    setBlockSearch(true);
    setInput(selectedResult);
    setResults([]);
    setNext(2);
  };

  return (
    <Fragment>
      <input
        className={className}
        type={type}
        placeholder={placeholder}
        value={input}
        onChange={handleInputChange}
        ref={inputRef}
      />
      {/*<ul>
        {results.map((place, index) => (
          <li
            key={place.id}
            className={index === selectedItem ? 'selected' : ''}
            onClick={() => handleResultSelection(place.description)}
          >
            {place.description}
          </li>
        ))}
        </ul>*/}
    </Fragment>
  );
};

export default Autocomplete;
