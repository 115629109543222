import React, { useEffect, useState/*, useContext*/ } from 'react';
import './GridView.scss';
import GridCard from '../grid-card/GridCard';
import GridCardSekeletonLoading from '../grid-card-skeleton-loading/GridCardSkeletonLoading';
import ListingService from '../../services/ListingService';
//import { UserContext } from '../../user-context/UserContext';

const GridView = ({ limit, skeletonLimit, sliding = false }) => {
    //const { user } = useContext(UserContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        // eslint-disable-next-line
    }, [/*user*/]);

    const getData = async () => {
        setLoading(true);
        const service = new ListingService();
        const response = await service.getListings();
        setData(response);
        setLoading(false);
    };

    return (
        <div className={`grid-view ${sliding ? 'horizontal-scrollable' : ''}`}>
            {!loading && <div className='row  pt-2 pb-3 '>
                {data.map((item, index) => {
                    return index < limit ? <GridCard item={item} key={index} /> : ''
                })}
            </div>}
            {loading && <div className='row pt-2 pb-3'>
                <GridCardSekeletonLoading skeletonLimit={skeletonLimit} />
            </div>}
        </div>
    );
};

export default GridView;
