import React from 'react';

const ListingPage = () => {
    return (
        <div className='container pt-2 page-fill'>
            <div className='text-center pt-5'>
                <h5>Lys by ons</h5>
            </div>
        </div>
    );
};

export default ListingPage;
