import React, { useEffect, useState, Fragment, useRef } from 'react';
import './SelectGuests.scss';

const SelectGuests = ({ className, type, placeholder, ariaLabel, input, setInput, setOutput }) => {
    const inputRef = useRef(null);

    const [adults, setAdults] = useState(0);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);
    const [pets, setPets] = useState(0);

    useEffect(() => {
        let tmp = '';
        if (adults > 0) {
            tmp += `${adults} Adults`;
        }
        if (children > 0) {
            if (tmp !== '') {
                tmp += ', ';
            }
            tmp += `${children} Children`;
        }
        if (infants > 0) {
            if (tmp !== '') {
                tmp += ', ';
            }
            tmp += `${infants} Infants`;
        }
        if (pets > 0) {
            if (tmp !== '') {
                tmp += ', ';
            }
            tmp += `${pets} Pets`;
        }
        setInput(tmp);
        setOutput(<div>
            <div className='row border-bottom mb-2 pb-2'>
                <div className='col-6'>
                    <div>
                        <label className='fw-bold'>Adults:</label>
                    </div>
                    <div>
                        <small>Ages 13 or above</small>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='my-2 text-end'>
                        <i className='material-icons font-large-mui rounded-circle border geust-btn' onClick={() => handleDecrement('adults')}>remove</i>
                        <label className='px-2 quest-count'>{adults}</label>
                        <i className='material-icons font-large-mui rounded-circle border geust-btn' onClick={() => handleIncrement('adults')}>add</i>
                    </div>
                </div>
            </div>
            <div className='row border-bottom mb-2 pb-2'>
                <div className='col-6'>
                    <div>
                        <label className='fw-bold'>Children:</label>
                    </div>
                    <div>
                        <small>Ages 2-12</small>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='my-2 text-end'>
                        <i className='material-icons font-large-mui rounded-circle border geust-btn' onClick={() => handleDecrement('children')}>remove</i>
                        <label className='px-2 quest-count'>{children}</label>
                        <i className='material-icons font-large-mui rounded-circle border geust-btn' onClick={() => handleIncrement('children')}>add</i>
                    </div>
                </div>
            </div>
            <div className='row border-bottom mb-2 pb-2'>
                <div className='col-6'>
                    <div>
                        <label className='fw-bold'>Infants:</label>
                    </div>
                    <div>
                        <small>Under 2</small>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='my-2 text-end'>
                        <i className='material-icons font-large-mui rounded-circle border geust-btn' onClick={() => handleDecrement('infants')}>remove</i>
                        <label className='px-2 quest-count'>{infants}</label>
                        <i className='material-icons font-large-mui rounded-circle border geust-btn' onClick={() => handleIncrement('infants')}>add</i>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <div>
                        <label className='fw-bold'>Pets:</label>
                    </div>
                    <div>
                        <small>Max 2</small>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='my-2 text-end'>
                        <i className='material-icons font-large-mui rounded-circle border geust-btn' onClick={() => handleDecrement('pets')}>remove</i>
                        <label className='px-2 quest-count'>{pets}</label>
                        <i className='material-icons font-large-mui rounded-circle border geust-btn' onClick={() => handleIncrement('pets')}>add</i>
                    </div>
                </div>
            </div>
        </div>);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adults, children, infants, pets]);

    useEffect(() => {
        if (input === '') {
            setAdults(0);
            setChildren(0);
            setInfants(0);
            setPets(0);
        } else {
            
        }
    }, [input]);


    const handleIncrement = (type) => {
        switch (type) {
            case 'adults':
                setAdults(adults + 1);
                break;
            case 'children':
                setChildren(children + 1);
                break;
            case 'infants':
                setInfants(infants + 1);
                break;
            case 'pets':
                setPets(pets + 1);
                break;
            default:
                break;
        }
    };

    const handleDecrement = (type) => {
        switch (type) {
            case 'adults':
                setAdults(adults > 0 ? adults - 1 : 0);
                break;
            case 'children':
                setChildren(children > 0 ? children - 1 : 0);
                break;
            case 'infants':
                setInfants(infants > 0 ? infants - 1 : 0);
                break;
            case 'pets':
                setPets(pets > 0 ? pets - 1 : 0);
                break;
            default:
                break;
        }
    };

    const handleInputChange = (event) => {
    };

    return (
        <Fragment>
            <input
                className={className}
                type={type}
                placeholder={placeholder}
                value={input}
                onChange={handleInputChange}
                ref={inputRef}
                aria-label={ariaLabel}
                readOnly
                style={{ cursor: 'pointer' }}
            />
        </Fragment>
    );
};

export default SelectGuests;
