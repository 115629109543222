import React from 'react';

const TermsAndConditionsPage = () => {
    return (
        <div className='container pt-2 page-fill'>
            <div className='text-center pt-5'>
                <h5>Terme en voorwaardes</h5>
            </div>
        </div>
    );
};

export default TermsAndConditionsPage;
