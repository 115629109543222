import React, { createContext, useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';

const UserContext = createContext({ user: {}, setUser: null, contextDoneLoading: false});

const UserProvider = ({ children }) => {
    // Add your provider logic here
    const [user, setUser] = useState({});

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setUser(user);
            } else {
                setUser({});
            }
            
        });
        return () => unsubscribe();
    }, [user]);

    const getProviders = () => {
        return {
            user,
            setUser
        }
    }

    return (
        <UserContext.Provider value={getProviders()}>
            {children}
        </UserContext.Provider>
      );
};

export { UserContext, UserProvider };