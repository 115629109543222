import React, { Fragment, useEffect, useState } from 'react';
import './ItemView.scss';
import ListingService from '../../services/ListingService';
import { AsyncImage } from 'loadable-image';
import { Blur } from 'transitions-kit';

import ItemViewSkeletonLoading from '../item-view-skeleton-loading/ItemViewSkeletonLoading';

const ItemView = ({ id }) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        // eslint-disable-next-line
    }, [id]);

    const getData = async () => {
        setLoading(true);
        const service = new ListingService();
        const response = await service.getListingById(id);
        setData(response);
        setLoading(false);
    };

    const [subtitle, setSubtitle] = useState('');
    useEffect(() => {
        let tmp = ''
        if (data.guests) {
            tmp += `${data.guests} guests`;
        }
        if (data.bedrooms) {
            if (tmp !== '')
                tmp += ', ';
            tmp += `${data.bedrooms} bedrooms`;
        }
        if (data.bathrooms) {
            if (tmp !== '')
                tmp += ', ';
            tmp += `${data.bathrooms} bathrooms`;
        }
        setSubtitle(tmp);
    }, [data]);

    return (
        <Fragment>
            {!loading ?
                <div className="item-view-component">
                    <div className='row d-md-block d-none'>
                        <div className='col-12 my-2 info-summary'>
                            <div className='item-title'>{data.name}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-6 pe-md-1'>
                            <AsyncImage
                                className='main-image'
                                src={data.thumbnail}
                                loader={<div style={{ background: '#EBEBEB' }} />}
                                Transition={Blur}
                                alt={data.name}
                            />
                        </div>
                        <div className='col-6 ps-1 d-md-block d-none'>
                            <div className='row'>
                                <div className='col-6 pe-1 pb-1'>
                                    <AsyncImage
                                        className='extra-image-01'
                                        src={data.thumbnail}
                                        loader={<div style={{ background: '#EBEBEB' }} />}
                                        Transition={Blur}
                                        alt={data.name}
                                    />
                                </div>
                                <div className='col-6 ps-1 pb-1'>
                                    <AsyncImage
                                        className='extra-image-02'
                                        src={data.thumbnail}
                                        loader={<div style={{ background: '#EBEBEB' }} />}
                                        Transition={Blur}
                                        alt={data.name}
                                    />
                                </div>
                                <div className='col-6 pe-1 pt-1'>
                                    <AsyncImage
                                        className='extra-image-03'
                                        src={data.thumbnail}
                                        loader={<div style={{ background: '#EBEBEB' }} />}
                                        Transition={Blur}
                                        alt={data.name}
                                    />
                                </div>
                                <div className='col-6 ps-1 pt-1'>
                                    <AsyncImage
                                        className='extra-image-04'
                                        src={data.thumbnail}
                                        loader={<div style={{ background: '#EBEBEB' }} />}
                                        Transition={Blur}
                                        alt={data.name}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-7 my-2 info-summary' style={{ minHeight: '800px' }}>
                            <div className='item-title  d-block d-md-none'>{data.name}</div>
                            <div className='item-description'>{data.description}</div>
                            <div className='item-subtitle'>{subtitle}</div>
                            <div className='item-rating'>
                                <i className="material-icons">star</i>{data.rating ? data.rating % 1 === 0 ? data.rating : data.rating.toFixed(2) : ' Nog geen resensies nie'}
                            </div>
                            <div className='item-divider' />
                        </div>
                        <div className='col-12 col-md-5 d-none d-md-block my-2 sticky-card-contianer'>
                            <div className='sticky-card' style={{ minHeight: '400px' }}>

                            </div>
                        </div>
                    </div>
                </div>
                :
                <ItemViewSkeletonLoading />
            }
        </Fragment>
    );

};

export default ItemView;