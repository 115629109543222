import React from 'react';
import { useParams } from 'react-router-dom';
import Item from '../components/item-view/ItemView';

const DetailPage = () => {
    const { id } = useParams();
    
    return (
        <div className='container page-fill'>
            <div className='row'>
                <div className='col-12'>
                    <Item id={id} />
                </div>
            </div>
        </div>
    );
};

export default DetailPage;
