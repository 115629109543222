import React, { useState } from 'react';
import './ContactUs.scss';

const ContactUs = () => {
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(null);
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(null);
    const [subject, setSubject] = useState('');
    const [subjectValid, setSubjectValid] = useState(null);
    const [message, setMessage] = useState('');
    const [messageValid, setMessageValid] = useState(null);

    const [showSuccess, setShowSuccess] = useState('');

    const handleContactUs = async (e) => {
        e.preventDefault();
        if (!subjectValid || !messageValid || !nameValid || !emailValid) {
            if (!nameValid) setNameValid(false);
            if (!emailValid) setEmailValid(false);
            if (!subjectValid) setSubjectValid(false);
            if (!messageValid) setMessageValid(false);
            return;
        }
        setShowSuccess("Dankie vir jou boodskap. Ons sal jou binnekort kontak.");
        setName('');
        setNameValid(null);
        setEmail('');
        setEmailValid(null);
        setSubject('');
        setSubjectValid(null);
        setMessage('');
        setMessageValid(null);
        setTimeout(() => {
            setShowSuccess("")

        }, 10000)
    }

    return (
        <div className='contact-us-component pt-5'>
            <div className='text-center'>
                <h5>Kontak ons</h5>
            </div>
            <form className="needs-validation mt-5" noValidate onSubmit={(e) => handleContactUs(e)}>
                {showSuccess === '' && <div className=" row g-3">
                    <div className='input-group col-12'>
                        <div className="form-floating">
                            <input type="text" className={`form-control ${nameValid === null ? '' : nameValid ? 'is-valid' : 'is-invalid'}`} id="floatingName" placeholder="Onderwerp" value={name} onChange={(e) => { setNameValid(e.target.value.trim()); setName(e.target.value) }} />
                            <label htmlFor="floatingName">Naam</label>
                            <div className="invalid-feedback">
                                Verskaf asseblief jou namm.
                            </div>
                        </div>
                    </div>
                    <div className='input-group col-12'>
                        <div className="form-floating">
                            <input type="email" className={`form-control ${emailValid === null ? '' : emailValid ? 'is-valid' : 'is-invalid'}`} id="floatingEmail" placeholder="E-pos adres" value={email} onChange={(e) => { setEmailValid(/^\S+@\S+\.\S+$/.test(e.target.value.trim())); setEmail(e.target.value) }} />
                            <label htmlFor="floatingEmail">E-pos adres</label>
                            <div className="invalid-feedback">
                                {`Verskaf asseblief ${email === '' ? 'jou' : 'geldige'} e-pos adres.`}.
                            </div>
                        </div>
                    </div>

                    <div className='input-group col-12'>
                        <div className="form-floating">
                            <input type="text" className={`form-control ${subjectValid === null ? '' : subjectValid ? 'is-valid' : 'is-invalid'}`} id="floatingSubject" placeholder="Onderwerp" value={subject} onChange={(e) => { setSubjectValid(e.target.value.trim()); setSubject(e.target.value) }} />
                            <label htmlFor="floatingSubject">Onderwerp</label>
                            <div className="invalid-feedback">
                                Verskaf asseblief 'n onderwerp.
                            </div>
                        </div>
                    </div>
                    <div className='input-group col-12'>
                        <div className="form-floating">
                            <textarea className={`form-control ${messageValid === null ? '' : messageValid ? 'is-valid' : 'is-invalid'}`} placeholder="Boodskap" id="floatingMessage" style={{ height: '100px' }} value={message} onChange={(e) => { setMessageValid(e.target.value.trim() !== ''); setMessage(e.target.value) }}></textarea>
                            <label htmlFor="floatingMessage">Boodskap</label>
                            <div className="invalid-feedback">
                                Verskaf asseblief 'n boodskap.
                            </div>
                        </div>
                    </div>
                    <div className='text-end'>
                        <button type="submit" className="btn btn-primary rounded-pill">Stuur</button>
                    </div>
                </div>}
                {showSuccess !== '' && <div className=" row g-3">
                    <div className='col-12 text-center'>
                        <p>{showSuccess}</p>
                    </div>
                </div>}
            </form>
            {/* Add your contact form or other content here */}
        </div>
    );
};

export default ContactUs;
