import React, { useState, useEffect } from 'react';
import './ScrollToTop.scss'; // You can create your own CSS file for styling

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsVisible(scrollTop > 100); // Adjust this value based on when you want the button to appear
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className={`scroll-to-top shadow-sm ${isVisible ? 'visible' : ''}`} onClick={scrollToTop}>
            <i className="material-icons font-large-mui">keyboard_arrow_up</i>
        </div>
    );
};

export default ScrollToTop;