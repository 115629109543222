import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../config/FirebaseConfig';

class AuthService {

    createUser = async (email, password) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            return user;
        }   catch (error) {
            return null;
        }
    }

    signIn = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            return user;
        }   catch (error) {
            return null;
        }
    }

    getCurrentUser = () => {
        return auth.currentUser;
    }
}

export default AuthService;