import React, { Fragment, useEffect, useRef, useState } from 'react';
import './SelectDates.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const SelectDates = ({ className, type, placeholder, ariaLabel, input, startDate, setStartDate, setEndDate, endDate, setOutput, setNext, monthsShown = 1 }) => {
    const inputRef = useRef(null);

    const [value, setValue] = useState('');

    useEffect(() => {
        if (input !== '' && input !== null) {
            let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            let now = new Date(input);
            setValue(now.getDate() + ' ' + months[now.getMonth()]);
        } else {
            setValue('');
        }
    }, [input]);

    useEffect(() => {
        setOutput(
            <DatePicker
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                monthsShown={monthsShown}
                selectsRange
                inline
                calendarClassName='select-dates'
                minDate={new Date()}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    const handleDateChange = (dates) => {
        if (dates[0] === null && dates[1] === null) {
            setNext(2);
        }
        if (dates[1] !== null) {
            setNext(4);
        }
        else if (dates[0] !== null) {
            setNext(3);
        }
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleInputChange = (event) => {
    };

    return (
        <Fragment>
            <input
                className={className}
                type={type}
                placeholder={placeholder}
                value={value === null ? '' : value}
                onChange={handleInputChange}
                ref={inputRef}
                aria-label={ariaLabel}
                readOnly
                style={{ cursor: 'pointer' }}
            />
        </Fragment>
    );
};

export default SelectDates;