import './App.scss';
import Navigation from './components/navigation/Navigation';
import NavigationRoutes from './routes/NavigationRoutes';
import Footer from './components/footer/Footer';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
const App = () => {

  return (
    <div className="app">
      <Navigation />
      <div className='page-container'>
        <NavigationRoutes />
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
