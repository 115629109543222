import React from 'react';
import './Currency.scss';

const Currency = ({ value }) => {
    return (
        <div className='currency-container'>
            R {value % 1 === 0 ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ZAR
        </div>
    );
};

export default Currency;
