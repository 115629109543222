import React, { Fragment, useEffect, useState } from 'react';
import './GridCard.scss';
import Currency from '../currency/Currency';
import { AsyncImage } from 'loadable-image'
import { Blur } from 'transitions-kit'
import { useNavigate } from 'react-router-dom';

const GridCard = ({ item }) => {
    const navigate = useNavigate();
    const [subtitle, setSubtitle] = useState('');
    useEffect(() => {
        let tmp = ''
        if (item.guests) {

            tmp += `${item.guests} guests`;
        }
        if (item.bedrooms) {
            if (tmp !== '')
                tmp += ', ';
            tmp += `${item.bedrooms} bedrooms`;
        }
        if (item.bathrooms) {
            if (tmp !== '')
                tmp += ', ';
            tmp += `${item.bathrooms} bathrooms`;
        }
        setSubtitle(tmp);
    }, [item]);

    return (
        <div className='grid-card-container col-12 col-sm-6 col-md-4 col-lg-3 mt-3 mb-2'>
            <div className='card' onClick={() => navigate(`/inligting/${item.id}`)}>
                <AsyncImage
                    className='card-img-top'
                    src={item.thumbnail}
                    loader={<div style={{ background: '#EBEBEB' }} />}
                    Transition={Blur}
                    alt={item.name}
                />
                <div className='card-fav'><i className="material-icons">favorite_border</i></div>
                <div className='card-body'>
                    {item?.rating && <div className='card-rating'>
                        <i className="material-icons">star</i>{item.rating % 1 === 0 ? item.rating : item.rating.toFixed(2)}
                    </div>}
                    <div className='card-title'>
                        {item?.name ? item.name : ''}
                    </div>
                    <div className="card-subtitle text-muted">
                        {subtitle ? subtitle : ' '}
                    </div>
                    <div className='card-text'>
                        {item.description ? item.description : ''}
                    </div>
                    <div className='card-price'>
                        {item.price ? <Fragment><Currency value={item.price} /> night</Fragment> : ''}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GridCard;
