import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { UserContext } from '../user-context/UserContext';
import HomePage from '../pages/HomePage';
import SearchPage from '../pages/SearchPage';
import DetailPage from '../pages/DetailPage';
import ListingPage from '../pages/ListingPage';
import CancellationPolicyPage from '../pages/CancellationPolicyPage';
import SiteMapPage from '../pages/SiteMapPage';
import WebsitePolicyPage from '../pages/WebsitePolicyPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';

const NavigationRoutes = () => {
    return (
        <UserContext.Consumer>
            {({ user, setUser }) => (
            <Routes>
                <Route exact={true} path="/" element={<HomePage />} />

                <Route exact={true} path="/soekbyons" element={<SearchPage />} />
                <Route exact={true} path="/inligting/:id" element={<DetailPage />} />

                <Route exact={true} path="/lysbyons" element={<ListingPage />} />
                
                <Route exact={true} path="/kansellasiebeleid" element={<CancellationPolicyPage />} />
                <Route exact={true} path="/werfkaart" element={<SiteMapPage />} />
                <Route exact={true} path="/webtuistebeleid" element={<WebsitePolicyPage />} />
                <Route exact={true} path="/termeenvoorwaardes" element={<TermsAndConditionsPage />} />
                

                <Route exact={true} path="*" element={<HomePage />} />
            </Routes>
            )}
        </UserContext.Consumer>
    );
}

export default NavigationRoutes;
