import React, { Fragment, useEffect, useState } from 'react';
import './TopSearch.scss';
import GooglePlacesAutocompleteSearch from '../google-places-autocomplete-search/GooglePlacesAutocompleteSearch';
import SelectGuests from '../select-guests/SelectGuests';
import SelectDates from '../select-dates/SelectDates';

import Popup from '../popup/Popup';

const TopSearch = () => {
    const [modalId, setModalId] = useState(null);

    const [fieldIndex, setFieldIndex] = useState(0);
    const [fieldHover, setFieldHover] = useState(0);

    const [where, setWhere] = useState('');
    const [checkIn, setCheckIn] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [guests, setGuests] = useState('');

    const [join, setJoin] = useState('');

    const [whereResults, setWhereResults] = useState(null);
    const [checkInResults, setCheckInResults] = useState(null);
    const [checkOutResults, setCheckOutResults] = useState(null);
    const [guestsResults, setGuestsResults] = useState(null);

    useEffect(() => {
        document.getElementById(`scroll-${fieldIndex}`)?.scrollIntoView({ block: "center", inline: "center", behavior: "smooth"});
    }, [fieldIndex]);

    const onSubmit = (e) => {
        setFieldIndex(0);
        e.preventDefault();
        if (checkIn !== '' && checkOut !== '') {
            let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            let i = new Date(checkIn);
            let g = guests.split(',');
            let s = 0;
            for (let i = 0; i < g.length; i++) {
                s += parseInt(g[i].trim().split(' ')[0]);
            }
            setJoin(`${i.getDate() + ' ' + months[i.getMonth()]}, ${guests !== '' ? s > 1 ? s + ' Gaste' : s + 'Gas' : 'Gaste'}`);
        } else if (guests !== '') {
            let g = guests.split(',');
            let s = 0;
            for (let i = 0; i < g.length; i++) {
                s += parseInt(g[i].trim().split(' ')[0]);
            }
            setJoin(`${'Datum'}, ${guests !== '' ? s > 1 ? s + ' Gaste' : s + ' Gas' : 'Gaste'}`);
        } else {
            setJoin('');
        }
        setModalId(null);
    };

    const onModal = () => {
        setModalId('searchModal');
    }

    return (
        <Fragment>
            {/* Desktop Search */}
            <div className={`top-search top-search-desktop d-none d-md-block rounded-pill shadow-sm ${fieldIndex !== 0 ? 'hover' : ''}`} onMouseLeave={() => setFieldHover(0)}>
                <form className='d-flex' onSubmit={(e) => onSubmit(e)}>
                    <div className={`form-input-container ${fieldIndex === 2 && fieldHover === 1 ? 'hover' : fieldIndex === 1 && fieldHover === 2 ? 'hover-right' : ''} ${fieldHover === 1 || fieldHover === 2 || fieldIndex === 1 || fieldIndex === 2 ? 'no-after' : ''}`}>
                        <div className={`form-input-box rounded-pill ${fieldIndex === 1 ? 'active' : ''} ${fieldIndex !== 1 && fieldHover === 1 ? 'hover' : ''}`} onClick={() => setFieldIndex(1)} onMouseEnter={() => setFieldHover(1)}>
                            <label className="form-label">Waar</label>
                            <GooglePlacesAutocompleteSearch className='form-control mr-sm-2 mw-100' placeholder='Bestemming' type='text' ariaLabel='Bestemming' input={where} setInput={(e) => setWhere(e)} setOutput={(e) => setWhereResults(e)} setNext={(e) => setFieldIndex(e)} />
                            {fieldIndex === 1 && where !== '' && <i className="material-icons clear-input" onClick={() => setWhere('')}>clear</i>}
                        </div>
                    </div>

                    <div className={`form-input-container ${fieldIndex === 2 && fieldHover === 1 ? 'hover-left' : fieldIndex === 1 && fieldHover === 2 ? 'hover-left' : fieldIndex === 2 && fieldHover === 3 ? 'hover-right' : fieldIndex === 3 && fieldHover === 2 ? 'hover-right' : ''} ${fieldHover === 2 || fieldHover === 3 || fieldIndex === 2 || fieldIndex === 3 ? 'no-after' : ''}`}>
                        <div className={`form-input-box rounded-pill ${fieldIndex === 2 ? 'active' : ''} ${fieldIndex !== 2 && fieldHover === 2 ? 'hover' : ''}`} onClick={() => setFieldIndex(fieldIndex === 2 ? 0 : 2)} onMouseEnter={() => setFieldHover(2)}>
                            <label className="form-label">Inklok</label>
                            <SelectDates className='form-control mr-sm-2' placeholder='Datum' type='text' aria-label='Datum' input={checkIn} startDate={checkIn} setStartDate={(e) => setCheckIn(e)} endDate={checkOut} setEndDate={(e) => setCheckOut(e)} setOutput={(e) => setCheckInResults(e)} setNext={(e) => setFieldIndex(e)} monthsShown={2} />
                            {fieldIndex === 2 && checkIn !== '' && <i className="material-icons clear-input" onClick={() => { setCheckIn(''); setCheckOut('') }}>clear</i>}
                        </div>
                    </div>

                    <div className={`form-input-container ${fieldIndex === 2 && fieldHover === 3 ? 'hover-left' : fieldIndex === 3 && fieldHover === 2 ? 'hover-left' : fieldIndex === 3 && fieldHover === 4 ? 'hover-right' : fieldIndex === 4 && fieldHover === 3 ? 'hover-right' : ''} ${fieldHover === 3 || fieldHover === 4 || fieldIndex === 3 || fieldIndex === 4 ? 'no-after' : ''}`}>
                        <div className={`form-input-box rounded-pill ${fieldIndex === 3 ? 'active' : ''} ${fieldIndex !== 3 && fieldHover === 3 ? 'hover' : ''}`} onClick={() => setFieldIndex(fieldIndex === 3 ? 0 : 3)} onMouseEnter={() => setFieldHover(3)}>
                            <label className="form-label">Uitklok</label>
                            <SelectDates className='form-control mr-sm-2' placeholder='Datum' type='text' aria-label='Datum' input={checkOut} startDate={checkIn} setStartDate={(e) => setCheckIn(e)} endDate={checkOut} setEndDate={(e) => setCheckOut(e)} setOutput={(e) => setCheckOutResults(e)} setNext={(e) => setFieldIndex(e)} monthsShown={2} />
                            {fieldIndex === 3 && checkOut !== '' && <i className="material-icons clear-input" onClick={() => setCheckOut('')}>clear</i>}
                        </div>
                    </div>

                    <div className={`form-input-container ${fieldIndex === 4 && fieldHover !== 3 ? 'active-left' : fieldIndex === 4 && fieldHover === 3 ? 'active-right-hover-left' : fieldIndex === 3 && fieldHover === 4 ? 'hover' : fieldHover === 4 ? 'hover-right' : ''} no-after`}>
                        <div className={`form-input-box rounded-pill ${fieldIndex === 4 ? 'active' : ''} ${fieldIndex !== 4 && fieldHover === 4 ? 'hover' : ''}`} onClick={() => setFieldIndex(fieldIndex === 4 ? 0 : 4)} onMouseEnter={() => setFieldHover(4)}>
                            <label className="form-label">Wie</label>
                            <SelectGuests className='form-control mr-sm-2' placeholder='Gaste' type='text' aria-label='Gaste' input={guests} setInput={(e) => setGuests(e)} setOutput={(e) => setGuestsResults(e)} />
                            {fieldIndex === 4 && guests !== '' && <i className="material-icons clear-input" onClick={() => setGuests('')}>clear</i>}
                        </div>
                    </div>

                    <div className={`form-input-container ${fieldIndex === 4 ? 'active' : fieldHover === 4 ? 'hover' : ''}`} >
                        <div className='form-input-button rounded-pill' onMouseEnter={() => setFieldHover(4)}>
                            <button className='btn btn-success my-2 my-sm-0 rounded-pill' type='submit' onMouseEnter={() => setFieldHover(0)} onMouseLeave={() => setFieldHover(4)}>
                                <i className="material-icons">search</i><span className='d-none d-lg-inline'>&nbsp; SOEK</span>
                            </button>
                        </div>
                    </div>
                </form>
                {((fieldIndex === 1 && whereResults !== null) || (fieldIndex === 2 && checkInResults !== null) || (fieldIndex === 3 && checkOutResults !== null) || (fieldIndex === 4 && guestsResults !== null)) && <div className={`top-dropdown shadow-sm ${fieldIndex === 1 ? 'left' : fieldIndex === 4 ? 'right' : 'center'}`}>
                    {fieldIndex === 1 && whereResults}
                    {fieldIndex === 2 && checkInResults}
                    {fieldIndex === 3 && checkOutResults}
                    {fieldIndex === 4 && guestsResults}
                </div>}
            </div>
            {/* Mobile Search */}
            <div className='top-search top-search-mobile d-block d-md-none rounded-pill shadow-sm'>
                <div className={`form-input-container`}>
                    <div className={`form-input-box rounded-pill`} onClick={() => onModal()}>
                        <label className="form-label">{where !== '' ? where : 'Waar'}</label>
                        <input
                            className={'form-control mr-sm-2 mw-100'}
                            type={'text'}
                            placeholder={'Datum, Gaste'}
                            value={join}
                            onChange={(e) => setJoin(e.target.value)}
                            readOnly
                        //ref={inputRef}
                        />
                        {/*join !== '' && <i className="material-icons clear-input" onClick={() => {setJoin(''); setWhere(''); setCheckIn(''); setCheckOut(''); setGuests('')}}>clear</i>*/}
                        {<i className="material-icons search-icon">search</i>}
                    </div>
                </div>
                {/* Search Modal */}
                {modalId === 'searchModal' && <Popup modalHeading={'Waar jag ons?'} modalId={modalId} setModalId={(e) => setModalId(e)} >
                    <Fragment>
                        <div className="modal-body modal-search">
                            <div className='modal-input' id={'scroll-1'}>
                                <div className={`form-input-container`}>
                                    <div className={`form-input-box rounded-pill `} onClick={() => setFieldIndex(1)} onMouseEnter={() => setFieldHover(1)}>
                                        <label className="form-label">Waar</label>
                                        <GooglePlacesAutocompleteSearch className='form-control mr-sm-2 mw-100' placeholder='Bestemming' type='text' ariaLabel='Bestemming' input={where} setInput={(e) => setWhere(e)} setOutput={(e) => setWhereResults(e)} setNext={(e) => setFieldIndex(e)} />
                                        {fieldIndex === 1 && where !== '' && <i className="material-icons clear-input" onClick={() => setWhere('')}>clear</i>}
                                    </div>
                                </div>
                                {fieldIndex === 1 && whereResults}
                            </div>
                            <div className='modal-input mt-4'  id={'scroll-2'}>
                                <div className={`form-input-container`}>
                                    <div className={`form-input-box rounded-pill `} onClick={() => setFieldIndex(fieldIndex === 2 ? 0 : 2)} onMouseEnter={() => setFieldHover(2)}>
                                        <label className="form-label">Inklok</label>
                                        <SelectDates className='form-control mr-sm-2' placeholder='Datum' type='text' aria-label='Datum' input={checkIn} startDate={checkIn} setStartDate={(e) => setCheckIn(e)} endDate={checkOut} setEndDate={(e) => setCheckOut(e)} setOutput={(e) => setCheckInResults(e)} setNext={(e) => setFieldIndex(e)} />
                                        {fieldIndex === 2 && checkIn !== '' && <i className="material-icons clear-input" onClick={() => { setCheckIn(''); setCheckOut('') }}>clear</i>}
                                    </div>
                                </div>
                                {fieldIndex === 2 && checkInResults}
                            </div>
                            <div className='modal-input mt-4'  id={'scroll-3'}>
                                <div className={`form-input-container`}>
                                    <div className={`form-input-box rounded-pill `} onClick={() => setFieldIndex(fieldIndex === 3 ? 0 : 3)} onMouseEnter={() => setFieldHover(3)}>
                                        <label className="form-label">Uitklok</label>
                                        <SelectDates className='form-control mr-sm-2' placeholder='Datum' type='text' aria-label='Datum' input={checkOut} startDate={checkIn} setStartDate={(e) => setCheckIn(e)} endDate={checkOut} setEndDate={(e) => setCheckOut(e)} setOutput={(e) => setCheckOutResults(e)} setNext={(e) => setFieldIndex(e)} />
                                        {fieldIndex === 3 && checkOut !== '' && <i className="material-icons clear-input" onClick={() => setCheckOut('')}>clear</i>}
                                    </div>
                                </div>
                                {fieldIndex === 3 && checkOutResults}
                            </div>
                            <div className='modal-input mt-4'  id={'scroll-4'}>
                                <div className={`form-input-container`}>
                                    <div className={`form-input-box rounded-pill`} onClick={() => setFieldIndex(fieldIndex === 4 ? 0 : 4)} onMouseEnter={() => setFieldHover(4)}>
                                        <label className="form-label">Wie</label>
                                        <SelectGuests className='form-control mr-sm-2' placeholder='Gaste' type='text' aria-label='Gaste' input={guests} setInput={(e) => setGuests(e)} setOutput={(e) => setGuestsResults(e)} />
                                        {fieldIndex === 4 && guests !== '' && <i className="material-icons clear-input" onClick={() => setGuests('')}>clear</i>}
                                    </div>
                                </div>
                                {fieldIndex === 4 && guestsResults}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className='btn btn-link rounded-pill' onClick={() => { setWhere(''); setCheckIn(''); setCheckOut(''); setGuests('') }} >Maak skoon</button>
                            <button type="button" className="btn btn-primary rounded-pill" onClick={(e) => onSubmit(e)} data-bs-dismiss="modal"><i className="material-icons">search</i>&nbsp; SOEK</button>
                        </div>
                    </Fragment>
                </Popup>}
            </div>
        </Fragment>
    );
};

export default TopSearch;
